<footer class="modulo-footer">
  <div class="container">
    <div class="content">
      <div class="row">
        <div class="col-lg-3 col-md-4">
          <div class="bloco bloco-01">
            <modulo-menu-footer></modulo-menu-footer>
            <modulo-position position="rede-social"></modulo-position>
          </div>
        </div>
        <div class="col-lg-3 col-md-4">
          <div class="bloco bloco-02">
            <modulo-position position="contato" template="footer"></modulo-position>
          </div>  
        </div>
        <div class="col-lg-6 col-md-4">
          <formas-de-pagamento></formas-de-pagamento>
          <figure class="mascote">
            <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/mascote/carro.png" alt="Mascote"  />
          </figure>  
        </div>   
      </div>  
    </div>    
  </div> 
  <div class="copyright">
    <div class="linha"></div>  
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-3">
          <div class="to-up" (click)="toTop()">
            <figure>
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/icons/alltour/arrow.svg" alt="Ir pra cima" />
            </figure>  
          </div>  
        </div> 
        <div class="col-md-4 col-5">
          <div class="date">
            <span>© copyright - 2020</span><br>
            <div class="cnpj">
              <strong>CNPJ: </strong>
              <span>22.958.710/0001-61</span>
            </div>
          </div>
        </div>  
        <div class="col-md-4 col-4">
          <div class="dm-logo">
            <a href="http://digitalmovement.com.br" target="__blank" title="Digital Movement - Desenvolvimento de Soluções">
              <span>desenvolvido por</span> 
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/assets/logos/dm/logo-digital-movement-2.svg" alt="Logo - Digital Movement" />
            </a>
          </div>
          <div class="dm-logo to-mobile">
            <a href="http://digitalmovement.com.br" target="__blank" title="Digital Movement - Desenvolvimento de Soluções">
              <span>desenvolvido por</span>    
              <img defaultImage="/images/pixel/bg.png" lazyLoad="/assets/logos/dm/logo-digital-movement-2.svg" alt="Logo - Digital Movement" />
            </a>
          </div> 
        </div>
      </div>  
    </div>  
  </div>  
</footer>
