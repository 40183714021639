import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '../../../../../services/api.service';
import { AppService } from '../../../../../services/app.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare var CardInfo:any;

@Component({
  selector: 'dm-cart-pagamento-cielo',
  templateUrl: './dm-cart-pagamento-cielo.component.html',
  styleUrls: ['./dm-cart-pagamento-cielo.component.scss']
})
export class DmCartPagamentoCieloComponent implements OnInit {

  @Input("sendPagamento") sendPagamento     = new EventEmitter();
  @Output("onSuccess")  onSuccess           = new EventEmitter();
  @Input("carrinho") carrinho               = null;
  @Input("formaPagamento") formaPagamento   = null;
  @Output("updateResponse")  updateResponse = new EventEmitter();
  
  public dataItem:any                     = {};
  public loader                           = false;
  public message  = "";
  public messageLoader              = "";
  public messageInfo                = {
    show: false,
    data: "",
    class: "",
    title: "Atenção"
  }
  public total = 0;

  constructor(
    private _api: ApiService,
    private _app: AppService,
    private recaptchaV3Service: ReCaptchaV3Service 
  ){}


   /**
   * 
   * Envia o pagamento
   * 
   */
  send(){

    try{

      this.message  = "Finalizando a Reserva. Aguarde!";
      this.loader   = true;

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token:any) => {

          this.dataItem.token = token;

          this._api.pagamento().finalizarReserva(this.dataItem).subscribe(response => {

            this.loader = false;

            if(response.status == 1){

              let dataFile = response.data.file;
              let dataPay  = response.data.pay;

              this.updateResponse.emit({
                dataFile: dataFile,
                dataPay: dataPay
              });  

              if(dataFile.status == 500){

                this._app.info(dataFile.data);

              }else if(dataPay.status == 500){

                this._app.info(dataPay.data);

              }else{
                this.onSuccess.emit(response);
              }

            }else{

              this._app.info(response.data);

            }

          },(response) => {

            this.loader = false;
            let data    = this._app.formatError(response);

            this._app.info(data.message);

          });

      },(response) => {

          this.loader = false;
          let data    = this._app.formatError(response);

          this._app.info(data.message);
          //this.subscribeMessage = data.message;

          //this.messageInfo.title = "Atenção";
          //this.messageInfo.data  = data.message;
          //this.messageInfo.show  = true;
          //this.messageInfo.class = "alert alert-danger";

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Seta a bandeira baseado no número do cartão
   * 
   */
  setBrand(){ 
    
    let info = new CardInfo(this.dataItem.cartao.numero_cartao);

    var cardInfo = new CardInfo(this.dataItem.cartao.numero_cartao,{
      brandsLogosPath: "/images/pagamento/brands/",
      brandLogoPolicy: "colored"
    });

    this.dataItem.cartao.brand      = cardInfo.brandAlias; 
    this.dataItem.cartao.brand_logo = cardInfo.brandLogoPng;

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      pagamento_id: this.formaPagamento.id, 
      apelido: this.formaPagamento.intermediador_apelido, 
      cartao: {
        numero_cartao: "",
        data_validade_mes: "",
        data_validade_ano: "",
        data_validade: "",
        nome_portador: "",
        codigo_seguranca: "",
        brand: null,
        brand_logo: null,
        parcelas: null
      }
    }

  }
  /**
   * 
   * Change número de parcelas
   * 
   */
  changeValueParcela(parcela){

    this.dataItem.cartao.parcelas = parcela;

  }
  /**
   * 
   * Set Data
   * 
   */
  setData(){

    if(this.carrinho != null){
      this.total = this.carrinho.total;
    }

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.sendPagamento.subscribe(response => {

      this.send();

    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.initDataItem();
    this.setData();
    this.onData();
  }


}
